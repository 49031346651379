module.exports = [{
      plugin: require('/opt/build/repo/en/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/en/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-25512776-1"},
    },{
      plugin: require('/opt/build/repo/en/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PS4JXX"},
    },{
      plugin: require('/opt/build/repo/en/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
